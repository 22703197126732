/**
 * Page data for the "il-servizio" page.
 */
import React, { ReactNode } from 'react';

export interface ServiceUseCase {
  superTitle: string;
  title: string;
  description: string;
  // Image string refers to the Object Fluid inside 'il-servizio' page query.
  image: string;
}

export const useCases: ServiceUseCase[] = [
  {
    superTitle: 'Ambito sanitario',
    title: 'Radioterapia',
    description:
      'Un ciclo di radioterapia spesso va dalle 10 alle 25 sedute consecutive. Per il malato è uno stress fisico e psicologico molto forte da affrontare solo, per un parente è difficile gestire e coniugare col lavoro. UGO subentra al familiare per garantire supporto ma anche sostenere la famiglia nell’organizzazione logistica.',
    image: 'usecase1',
  },
  {
    superTitle: 'Ambito sanitario',
    title: 'Ciclo di terapia',
    description:
      'Sono impegni dalla frequenza e durata molto complesse che i familiari caregiver spesso faticano molto a gestire e coniugare con il resto degli impegni quotidiani. UGO vuole dare sollievo alle famiglie senza mai far mancare al paziente il riferimento e il supporto necessari per la sua tranquillità e sicurezza.',
    image: 'usecase2',
  },
  {
    superTitle: 'Ambito sanitario',
    title: 'Day Hospital',
    description:
      'Le tipologie di intervento sono molteplici ma spesso è gradito un braccio a cui sostenersi, una persona che porti la borsa per noi o semplicemente che guidi al posto nostro. UGO è un riferimento durante la giornata in ospedale e nella gestione di un rientro a casa sicuro e con tutto il necessario.',
    image: 'usecase3',
  },
  {
    superTitle: 'Ambito sanitario',
    title: 'Visita di controllo',
    description:
      'UGO è l’accompagnatore perfetto: tempestivo, non vincolante, lo richiedi solo per quando necessiti anche per poche ore.',
    image: 'usecase4',
  },
  {
    superTitle: 'Ambito sanitario',
    title: 'Visita oculistica',
    description:
      'Puoi andare dall’oculista comodamente in macchina! Per il ritorno prenoti UGO che guida la tua auto al posto tuo per riaccompagnati a casa in sicurezza anche dopo la dilatazione pupillare.',
    image: 'usecase5',
  },
  {
    superTitle: 'Ambito sanitario',
    title: 'Fisioterapia',
    description:
      'Lunghi cicli, numerose sedute, spesso legati a limitazioni motorie e impossibilità alla guida. UGO non solo guida per te fino a destinazione ma ti assiste all’interno in ogni spostamento fino al rientro a casa.',
    image: 'usecase6',
  },
  {
    superTitle: 'Quotidianità',
    title: 'Visita ai nipotini',
    description:
      'Puoi vedere i tuoi nipoti tutte le volte che vuoi senza dipendere dai tuoi figli per gli spostamenti: prenoti UGO che ti accompagna, ti affianca in piccole commissioni e ti viene a prendere per rientrare a casa.',
    image: 'usecase7',
  },
  {
    superTitle: 'Quotidianità',
    title: 'Fare la spesa',
    description:
      'Autonomia e libertà ma sempre con il supporto di un braccio robusto che porti le borse per te e ti aiuti a raggiungere i tuoi negozi di fiducia.',
    image: 'usecase8',
  },
  {
    superTitle: 'Quotidianità',
    title: 'Disbrigo pratiche',
    description:
      'La burocrazia talvolta è ostica per complessità dei moduli, talvolta per le nuove tecnologie in uso. Chiedi aiuto a UGO per non disturbare tuo figlio sempre troppo preso con il lavoro.',
    image: 'usecase9',
  },
];

interface HowItWorks {
  title: string;
  description: string | ReactNode;
  image: string;
}

export const howItWorks: HowItWorks[] = [
  {
    title: '1. Chiedi un preventivo',
    description:
      'Contatti il servizio clienti attraverso il sito, la mail o il centralino, per chiedere un preventivo. Il preventivo è su misura e sempre senza impegno. Scegli il servizio di cui hai bisogno, indica per quante ore e se hai bisogno o no di uno spostamento in auto e scopri quanto costa.',
    image: 'services1',
  },
  {
    title: '2. Confermi e organizziamo il servizio',
    description:
      'Se il preventivo rispetta le tue aspettative, confermi. Sono sufficienti 24 ore di preavviso. Noi organizziamo il servizio e ti inviamo la conferma con i dettagli e i riferimenti del tuo operatore di riferimento. Il tuo UGO ti chiamerà prima del servizio, per conoscervi e accordarvi su ogni dettaglio.',
    image: 'services2',
  },
  {
    title: '3. Sei sicuro e protetto',
    description: (
      <>
        Ogni servizio viene svolto dagli operatori nel massimo rispetto delle
        norme di sicurezza e prevenzione. Gli operatori indossano i dispositivi
        di protezione individuale, igienizzano e arieggiano l’auto, mantengono
        il distanziamento e segnalano ogni situazione a rischio. Puoi leggere il
        protocollo di sicurezza completo a questo{' '}
        <a
          className="text-primary-500 font-bold"
          href="https://hellougo.com/protocollo-sicurezza-covid19/"
        >
          link
        </a>
        .
      </>
    ),
    image: 'services3',
  },
  {
    title: '4. Paghi e lasci una recensione',
    description:
      'Il preventivo serve ad avere un ordine di grandezza ma il pagamento avviene dopo il servizio, a fronte del riepilogo condiviso. Il pagamento può avvenire tramite carta, bancomat, Paypal o bonifico. Dopo ogni servizio raccogliamo il tuo feedback per avere sotto controllo il corretto svolgimento e la soddisfazione dei nostri utenti. Se hai qualcosa da segnalare, contatta senza esitazione il nostro servizio clienti: per noi è importante per migliorare di continuo.',
    image: 'services4',
  },
];

export const duringService: HowItWorks[] = [
  {
    title: 'CONTATTO COSTANTE',
    description:
      'L’operatore UGO è sempre accanto alla persona e aggiorna i famigliari per lasciarli tranquilli.',
    image: 'during1',
  },
  {
    title: 'MONITORAGGIO CONTINUO',
    description:
      'L’operatore è formato e attento per non perdere mai di vista l’utente e rispondere alle sue necessità. ',
    image: 'during2',
  },
  {
    title: 'CONTROLLO FINALE',
    description:
      'Prima di salutarsi, l’operatore UGO si assicura che l’utente stia bene e non abbia bisogno di altro. ',
    image: 'during3',
  },
];

interface AfterService {
  title: string;
  description: string;
}

export const afterService: AfterService[] = [
  {
    title: 'Pagamento',
    description:
      'Il servizio viene prenotato a fronte di un preventivo ma il pagamento avviene dopo il servizio, per assicurare massima flessibilità e un servizio su misura. Si può pagare con carta o bancomat, bonifico, Paypal o contanti. ',
  },
  {
    title: 'Feedback',
    description:
      'Dopo ogni servizio raccogliamo il feedback che ci permette di conoscere la soddisfazione dei nostri utenti e scoprire come migliorare il servizio. Per ogni segnalazione o dubbio, il nostro Customer Service è a disposizione. ',
  },
];
